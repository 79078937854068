import header from '../components/header/Header.i18n.en';
import quickAbout from '../components/sections/quickAbout/QuickAbout.i18n.en';
import footer from '../components/footer/Footer.i18n.en';
import contactMeForm from '../components/contactMeForm/ContactMeForm.i18n.en';
import contacts from '../screens/contacts/Contacts.i18n.en';
import contact from '../components/sections/contact/Contact.i18n.en';
import myJourney from '../components/sections/myJourney/MyJourney.i18n.en';
import notFound from '../screens/notFound/NotFound.i18n.en';
import projectCard from '../components/projectCard/ProjectCard.i18n.en';
import about from '../screens/about/About.i18n.en';

export const enDictionary = {
    header,
    quickAbout,
    footer,
    contactMeForm,
    contacts,
    contact,
    myJourney,
    notFound,
    projectCard,
    about,
};
