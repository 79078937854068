import { IProject } from '../interfaces/interfaces';
import ECommerceImage from '../assets/images/ecommerce.png';
import ECommerceImage1 from '../assets/images/ecommerce1.png';
import ECommerceImage2 from '../assets/images/ecommerce2.png';
import ECommerceImage3 from '../assets/images/ecommerce3.png';
import ECommerceImage4 from '../assets/images/ecommerce4.png';
import TennisMachesImage from '../assets/images/tennis.png';
import TennisMachesImage1 from '../assets/images/tennis1.png';
import TennisMachesImage2 from '../assets/images/tennis2.png';
import NoImageImage from '../assets/images/no-image.png';

export const projects: IProject[] = [
    {
        name: 'E-Commerce',
        images: [
            {
                image: ECommerceImage,
                default: true,
            },
            {
                image: ECommerceImage1,
                default: false,
            },
            {
                image: ECommerceImage2,
                default: false,
            },
            {
                image: ECommerceImage3,
                default: false,
            },
            {
                image: ECommerceImage4,
                default: false,
            },
        ],
        tech: ['React', 'TypeScript', 'MobX', 'MongoDB', 'Express (NodeJS)', 'Vercel', 'Twilio'],
        description: 'Full stack development of E-Commerce web application with Mobile & Descktop customization',
    },
    {
        name: 'Tennis Matches',
        images: [
            {
                image: TennisMachesImage,
                default: true,
            },
            {
                image: TennisMachesImage1,
                default: false,
            },
            {
                image: TennisMachesImage2,
                default: false,
            },
        ],
        tech: ['React JS', 'Redux', 'MySQL', 'Express (NodeJS)', 'Vercel', 'PlanetScale'],
        description: 'Full stack development of web application that empowered users to store and track their matches score against other player',
    },
    {
        name: '*Graduate* Restaurant - Menu and Orders Management App - Mobile',
        images: [
            {
                image: NoImageImage,
                default: true,
            },
        ],
        tech: ['React Native', 'MongoDB', 'Express (NodeJS)', 'Twilio', 'Heroku'],
        description:
            'Shipping orders and self-pickups, allowing users to make orders from the restaurant. Features include various restaurant menus, navigation, and contact',
    },
];
