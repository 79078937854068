import { SocialMedia, SocialMediaUrl } from '../emuns/enums';
import { IClientConfig } from '../interfaces/interfaces';
import { ReactComponent as FaceBookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as IsntagramIcon } from '../assets/icons/instagram.svg';
import { ReactComponent as TiktokIcon } from '../assets/icons/tiktok.svg';
import { ReactComponent as LinkdinIcon } from '../assets/icons/linkedin.svg';
import { ReactComponent as GitlabIcon } from '../assets/icons/gitlab.svg';
import { PHONE_NUMBER } from '../constants/constants';

const ClientConfig: IClientConfig = {
    socialMedia: [
        {
            name: SocialMedia.FACEBOOK,
            url: SocialMediaUrl.FACEBOOK,
            icon: <FaceBookIcon />,
        },
        {
            name: SocialMedia.INSTAGRAM,
            url: SocialMediaUrl.INSTAGRAM,
            icon: <IsntagramIcon />,
        },
        {
            name: SocialMedia.TIKTOK,
            url: SocialMediaUrl.TIKTOK,
            icon: <TiktokIcon />,
        },
        {
            name: SocialMedia.LINKDIN,
            url: SocialMediaUrl.LINKDIN,
            icon: <LinkdinIcon />,
        },
        {
            name: SocialMedia.GITLAB,
            url: SocialMediaUrl.GITLAB,
            icon: <GitlabIcon />,
        },
    ],
    phoneNumber: PHONE_NUMBER,
    apiBaseHost: 'https://protfolio-server-three.vercel.app',
};

export default ClientConfig;
