import React from 'react';
import { observer } from 'mobx-react';
import QuickAbout from '../../components/sections/quickAbout/QuickAbout';
import Contact from '../../components/sections/contact/Contact';
import MyJourney from '../../components/sections/myJourney/MyJourney';

const Home = observer(() => {
    return (
        <>
            <QuickAbout />
            <MyJourney />
            <Contact />
        </>
    );
});

export default Home;
