import { useState } from 'react';
import projectCardStyle from './ProjectCard.module.scss';
import { IProject } from '../../interfaces/interfaces';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IModal from '../elements/iModal/IModal';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImagesCarousle from '../elements/imagesCarousel/ImagesCarousel';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

interface IProps {
    project: IProject;
    className: string;
}

const ProjectCard = (props: IProps) => {
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const { project, className } = props;
    const { t } = useTranslation();
    const mainImage = project?.images?.find((image) => image.default);

    const closeModalHandler = () => {
        setShowImageModal(false);
    };
    return (
        <div className={`${projectCardStyle.projectCardContainer} ${className}`}>
            <div className='row'>
                <div className={`${projectCardStyle.columnContainer} col-12 col-md-6`}>
                    <img
                        onClick={() => {
                            setShowImageModal(true);
                        }}
                        src={mainImage?.image}
                        alt=''
                        className={projectCardStyle.image}
                    />
                    <ZoomInIcon
                        onClick={() => {
                            setShowImageModal(true);
                        }}
                        className={projectCardStyle.zoomIcon}
                    />
                </div>
                <div className={`${projectCardStyle.columnContainer} col-12 col-md-6`}>
                    <div className={`${projectCardStyle.title} row`}>
                        <span>{project.name}</span>
                    </div>
                    <div className={`${projectCardStyle.tech} row`}>
                        <span>{t('projectCard.tech')}</span>
                        {project?.tech?.map((tech, i) => (
                            <span key={i}>{tech},</span>
                        ))}
                    </div>
                    <div className={`${projectCardStyle.description} row`}>
                        <span>{project.description}</span>
                    </div>
                    <div className={`${projectCardStyle.git} row`}>
                        <span>
                            {t('projectCard.git')} <ArrowForwardIcon />
                        </span>
                    </div>
                </div>
            </div>
            <IModal isOpen={showImageModal} closeModalHandler={closeModalHandler}>
                <ImagesCarousle images={project.images} />
            </IModal>
        </div>
    );
};

export default ProjectCard;
