import { useTranslation } from 'react-i18next';
import notFoundStyle from './NotFound.module.scss';
import { observer } from 'mobx-react';

const NotFound = observer(() => {
    const { t } = useTranslation();
    return (
        <div className={`${notFoundStyle.notFoundContainer}`}>
            <div className={`${notFoundStyle.mainRow} row`}>
                <span>{t('notFound.notFoundMessage')}</span>
            </div>
        </div>
    );
});

export default NotFound;
