import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import headerStyle from './Header.module.scss';
import { RoutesUrl } from '../../router/RouterUrls';
import { useNavigate } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import ProtfolioStore from '../../stores/protfolio/Protfolio.store';
import { PROTFOLIO_STORE } from '../../stores/constants';
import rootStores from '../../stores/index';
import IModal from '../elements/iModal/IModal';
import { Languages } from '../../emuns/enums';

const protfolioStore = rootStores[PROTFOLIO_STORE] as ProtfolioStore;

const Header = observer(() => {
    const [showLanguageModal, setShowLanguageModal] = useState<boolean>(false);
    const { activeRoute, setActiveRoute, setIsLoading } = protfolioStore;

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const navigationHandler = (route: string) => {
        setActiveRoute(route);
        navigate(route);
    };

    const closeModalHandle = () => {
        setShowLanguageModal(false);
    };

    const selectLanguageHandler = async (language: string) => {
        try {
            await i18n.changeLanguage(language);
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 1500);
            closeModalHandle();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <header className={`${headerStyle.headerContainer}`}>
                <div className={`${headerStyle.headerOptionContainer} ${activeRoute === RoutesUrl.home ? headerStyle.activeHeaderOption : ''}`}>
                    <span
                        onClick={() => {
                            navigationHandler(RoutesUrl.home);
                        }}
                    >
                        {t('header.home')}
                    </span>
                </div>
                <div className={`${headerStyle.headerOptionContainer} ${activeRoute === RoutesUrl.about ? headerStyle.activeHeaderOption : ''}`}>
                    <span
                        onClick={() => {
                            navigationHandler(RoutesUrl.about);
                        }}
                    >
                        {t('header.about')}
                    </span>
                </div>
                <div className={`${headerStyle.headerOptionContainer} ${activeRoute === RoutesUrl.projects ? headerStyle.activeHeaderOption : ''}`}>
                    <span
                        onClick={() => {
                            navigationHandler(RoutesUrl.projects);
                        }}
                    >
                        {t('header.projects')}
                    </span>
                </div>
                <div className={`${headerStyle.headerOptionContainer} ${activeRoute === RoutesUrl.contacts ? headerStyle.activeHeaderOption : ''}`}>
                    <span
                        onClick={() => {
                            navigationHandler(RoutesUrl.contacts);
                        }}
                    >
                        {t('header.contacts')}
                    </span>
                </div>
                <div className={headerStyle.languagesIconContainer}>
                    <LanguageIcon onClick={() => setShowLanguageModal(true)} className={headerStyle.languagesIcon} />
                </div>
            </header>
            <IModal isOpen={showLanguageModal} closeModalHandler={closeModalHandle}>
                <div className={headerStyle.selectLanguageModalContainer}>
                    <div onClick={() => selectLanguageHandler(Languages.ENGLISH)} className={`${headerStyle.language} row`}>
                        <span>{t('header.english')}</span>
                    </div>
                    <div onClick={() => selectLanguageHandler(Languages.HEBREW)} className={`${headerStyle.language} row`}>
                        <span> {t('header.hebrew')}</span>
                    </div>
                </div>
            </IModal>
        </>
    );
});

export default Header;
