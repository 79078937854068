import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import iTextInputStyle from './ITextInput.module.scss';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../../emuns/enums';

interface IProps {
    size: 'small' | 'medium';
    onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    variant: 'outlined' | 'standard' | 'filled';
    error: boolean | undefined;
    helperText: string | false | undefined;
    value: string;
    multiline?: boolean;
    inputLabel: string;
    type?: string;
    maxLength: number;
}

const ITextInput = (props: IProps) => {
    const { size, onChangeHandler, variant, error, helperText, value, inputLabel, type, multiline, maxLength } = props;
    const {
        i18n: { language },
    } = useTranslation();

    const inputProps = { maxLength };

    return (
        <div className={`${iTextInputStyle.textInputContainer}`}>
            <InputLabel className={`${iTextInputStyle.textInputLabel} ${language === Languages.HEBREW ? iTextInputStyle.he : ''}`}>
                {inputLabel}
            </InputLabel>
            <TextField
                size={size}
                onChange={onChangeHandler}
                fullWidth={true}
                variant={variant}
                value={value}
                error={error}
                helperText={helperText}
                type={type}
                multiline={multiline}
                rows={multiline ? 5 : 1}
                inputProps={inputProps}
            />
        </div>
    );
};

export default ITextInput;
