import quickAboutStyle from './QuickAbout.module.scss';
import ProfileImage from '../../../assets/images/profile.png';
import { useTranslation } from 'react-i18next';

const QuickAbout = () => {
    const { t } = useTranslation();
    return (
        <section className={quickAboutStyle.quickAboutContainer}>
            <div className={`${quickAboutStyle.informationRow} row`}>
                <div className={`${quickAboutStyle.descriptionsContainer} col-12 col-md-7`}>
                    <div className={`${quickAboutStyle.title} ${quickAboutStyle.animation}`}>
                        <span>{t('quickAbout.title')}</span>
                    </div>
                    <div className={`${quickAboutStyle.role} ${quickAboutStyle.animation}`}>
                        <span>{t('quickAbout.role')}</span>
                    </div>
                    <div className={quickAboutStyle.description}>
                        <span>{t('quickAbout.description')}</span>
                    </div>
                </div>
                <div className={`${quickAboutStyle.profileImageContainer} col-12 col-md-5`}>
                    <img src={ProfileImage} className={quickAboutStyle.profileImage} alt='' />
                </div>
            </div>
        </section>
    );
};

export default QuickAbout;
