import ProtfolioStore from './protfolio/Protfolio.store';

import { PROTFOLIO_STORE } from './constants';

const protfolioStore = new ProtfolioStore();

const rootStores = {
    [PROTFOLIO_STORE]: protfolioStore,
};

export default rootStores;
