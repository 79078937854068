import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enDictionary } from './locales/en';
import { heDictionary } from './locales/he';
import { Languages } from './emuns/enums';

const resources = {
    en: {
        translation: enDictionary,
    },
    he: {
        translation: heDictionary,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: Languages.ENGLISH, // Default language
    interpolation: {
        escapeValue: false, //Allow you to include variables in translations
    },
});

export default i18n;
