import header from '../components/header/Header.i18n.he';
import quickAbout from '../components/sections/quickAbout/QuickAbout.i18n.he';
import contacts from '../screens/contacts/Contacts.i18n.he';
import contactMeForm from '../components/contactMeForm/ContactMeForm.i18n.he';
import myJourney from '../components/sections/myJourney/MyJourney.i18n.he';
import contact from '../components/sections/contact/Contact.i18n.he';
import about from '../screens/about/About.i18n.he';
import footer from '../components/footer/Footer.i18n.he';

export const heDictionary = { header, quickAbout, contacts, about, footer, contactMeForm, contact, myJourney };
