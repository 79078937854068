const contactMeForm = {
    requiredField: 'Require Filed',
    invalidEmail: 'Invalid Email Address',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    submit: 'Send',
};

export default contactMeForm;
