const header = {
    home: 'Home',
    about: 'About',
    projects: 'Projects',
    contacts: 'Contacts',
    english: 'English',
    hebrew: 'עברית',
};

export default header;
