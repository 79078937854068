import React, { ReactNode } from 'react';
import iModalStyle from './IModal.module.scss';
import Modal from '@mui/material/Modal';

interface IProps {
    isOpen: boolean;
    closeModalHandler: () => void;
    children: ReactNode;
}

const IModal = (props: IProps) => {
    const { isOpen, closeModalHandler, children } = props;
    return (
        <Modal open={isOpen} onClose={closeModalHandler}>
            <div className={iModalStyle.iModalContainer}>{children}</div>
        </Modal>
    );
};

export default IModal;
