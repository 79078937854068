import { ISkill } from '../interfaces/interfaces';
import { ReactComponent as ReactIcon } from '../assets/icons/react.svg';
import { ReactComponent as NpmIcon } from '../assets/icons/npm.svg';
import { ReactComponent as NodeJsIcon } from '../assets/icons/nodejs.svg';
import { ReactComponent as TypescriptIcon } from '../assets/icons/typescript.svg';
import { ReactComponent as GitIcon } from '../assets/icons/git.svg';
import { ReactComponent as JavaScriptIcon } from '../assets/icons/javascript.svg';
import { ReactComponent as GitLabIcon } from '../assets/icons/gitlab.svg';
import { ReactComponent as GitHubIcon } from '../assets/icons/github.svg';
import { ReactComponent as HtmlIcon } from '../assets/icons/html.svg';
import { ReactComponent as CssIcon } from '../assets/icons/css.svg';
import { ReactComponent as ReduxIcon } from '../assets/icons/redux.svg';
import { ReactComponent as MySqlIcon } from '../assets/icons/mysql.svg';
import { ReactComponent as MongoIcon } from '../assets/icons/mongo.svg';
export const skills: ISkill[] = [
    {
        name: 'react',
        icon: <ReactIcon />,
    },
    {
        name: 'npm',
        icon: <NpmIcon />,
    },
    {
        name: 'node',
        icon: <NodeJsIcon />,
    },
    {
        name: 'typescript',
        icon: <TypescriptIcon />,
    },
    {
        name: 'git',
        icon: <GitIcon />,
    },
    {
        name: 'javascript',
        icon: <JavaScriptIcon />,
    },
    {
        name: 'gitlab',
        icon: <GitLabIcon />,
    },
    {
        name: 'github',
        icon: <GitHubIcon />,
    },
    {
        name: 'html',
        icon: <HtmlIcon />,
    },
    {
        name: 'css',
        icon: <CssIcon />,
    },
    {
        name: 'redux',
        icon: <ReduxIcon />,
    },
    {
        name: 'mysql',
        icon: <MySqlIcon />,
    },
    {
        name: 'mongodb',
        icon: <MongoIcon />,
    },
];
