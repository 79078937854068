import autoPlayCarousleStyle from './AutoPlayCarousle.module.scss';
import { skills } from '../../data/skills';

const AutoPlayCarousle = () => {
    return (
        <div className={autoPlayCarousleStyle.carouselContainer}>
            <div className={autoPlayCarousleStyle.carouselTrack}>
                {skills?.map((skill, i) => (
                    <div key={i} className={autoPlayCarousleStyle.carouselCard}>
                        {skill.icon}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AutoPlayCarousle;
