import ClientConfig from '../config/index';
import { ISendEmailPayload } from '../interfaces/interfaces';

import { BaseHttpFetcher } from './BaseHttpFetcher';

const protfolioPrefix = 'api';

class AuthFetcher extends BaseHttpFetcher {
    async sendEmailMessage(payload: ISendEmailPayload): Promise<any> {
        return await this.post(`${protfolioPrefix}/sendEmail`, payload);
    }
}

const protfolioInstance = new AuthFetcher('/', ClientConfig.apiBaseHost);
export default protfolioInstance;
