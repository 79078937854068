import { action, makeObservable, observable } from 'mobx';
import { RoutesUrl } from '../../router/RouterUrls';
import { ISendEmailPayload } from '../../interfaces/interfaces';
import ProtfolioFetcher from '../../fetchers/Protfolio.fetcher';
import toastUtilInstance from '../../utils/ToastUtil';

class ProtfolioStroe {
    @observable
    isLoading: boolean = false;

    @observable
    activeRoute: string = RoutesUrl.home;

    constructor() {
        makeObservable(this);
    }

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    setActiveRoute = (activeRoute: string) => {
        this.setIsLoading(true);
        this.activeRoute = activeRoute;
        setTimeout(() => {
            this.setIsLoading(false);
        }, 1500);
    };

    @action
    sendEmail = async (payload: ISendEmailPayload) => {
        try {
            this.setIsLoading(true);
            const { data } = await ProtfolioFetcher.sendEmailMessage(payload);
            toastUtilInstance.success(data.message);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : 'Some error occurred.';
            toastUtilInstance.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default ProtfolioStroe;
