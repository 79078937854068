export enum SocialMedia {
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    TIKTOK = 'tiktok',
    LINKDIN = 'linkdin',
    GITLAB = 'gitlab',
}

export enum SocialMediaUrl {
    FACEBOOK = 'https://www.facebook.com/TomerHalali',
    INSTAGRAM = 'https://www.instagram.com/tomerhalali',
    TIKTOK = 'https://www.tiktok.com/@tomer.halali',
    LINKDIN = 'https://www.linkedin.com/in/tomer-halali-a40235185',
    GITLAB = 'https://gitlab.com/TomerHalali',
}

export enum Languages {
    HEBREW = 'he',
    ENGLISH = 'en',
}
