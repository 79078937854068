import React, { useEffect } from 'react';
import appStyle from './App.module.scss';
import { observer } from 'mobx-react';
import Header from './components/header/Header';
import { BrowserRouter } from 'react-router-dom';
import Router from './router/Router';
import Footer from './components/footer/Footer';
import Loader from './components/elements/loader/Loader';
import { useTranslation } from 'react-i18next';
import ProtfolioStore from './stores/protfolio/Protfolio.store';
import { PROTFOLIO_STORE } from './stores/constants';
import rootStores from './stores/index';
import Toast from './components/elements/toast/Toast';
import { Languages } from './emuns/enums';
import { SpeedInsights } from '@vercel/speed-insights/react';

const protfolioStore = rootStores[PROTFOLIO_STORE] as ProtfolioStore;

const App = observer(() => {
    const { activeRoute, isLoading, setActiveRoute } = protfolioStore;

    const {
        i18n: { language },
    } = useTranslation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setActiveRoute(window.location.pathname);
    }, [activeRoute, setActiveRoute]);
    return (
        <>
            <BrowserRouter>
                <div className={`${isLoading ? appStyle.blurBackground : ''} ${language === Languages.HEBREW ? appStyle.he : ''}`}>
                    <SpeedInsights />
                    <Header />
                    <Router />
                    <Footer />
                    <Toast />
                </div>
            </BrowserRouter>
            {isLoading && <Loader />}
        </>
    );
});

export default App;
