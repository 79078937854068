import React, { useRef } from 'react';
import contactMeFormStyle from './ContactMeForm.module.scss';
import commonStyles from '../../styles/commonStyles.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import ITextInput from '../elements/iTextInput/ITextInput';
import IButton from '../elements/iButton/IButton';
import { ISendEmailPayload } from '../../interfaces/interfaces';

interface IProps {
    sumbitFormHandler: (payload: ISendEmailPayload) => void;
}

const ContactMeForm = (props: IProps) => {
    const { sumbitFormHandler } = props;
    const { t } = useTranslation();
    const captchaRef = useRef<ReCAPTCHA | null>(null);

    const validationSchema = yup.object().shape({
        senderName: yup.string().required(t('contactMeForm.requiredField')),
        senderEmail: yup.string().email(t('contactMeForm.invalidEmail')).required(t('contactMeForm.requiredField')),
        message: yup.string().required(t('contactMeForm.requiredField')),
    });

    const formik = useFormik({
        initialValues: {
            senderName: '',
            senderEmail: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let captchaV2Token = '';
            if (captchaRef.current.getValue()) {
                captchaV2Token = captchaRef.current.getValue();
            }
            captchaRef.current.reset();
            formik.resetForm();

            const payload = {
                ...values,
                captchaV2Token,
            };
            sumbitFormHandler(payload);
        },
    });

    return (
        <div className={`${contactMeFormStyle.contactMeContainer}`}>
            <form onSubmit={formik.handleSubmit}>
                <div className={`${contactMeFormStyle.inputContainer} row`}>
                    <ITextInput
                        size={'medium'}
                        onChangeHandler={formik.handleChange('senderName')}
                        variant={'outlined'}
                        error={formik.touched.senderName && Boolean(formik.errors.senderName)}
                        helperText={formik.touched.senderName && formik.errors.senderName}
                        value={formik.values.senderName}
                        inputLabel={t('contactMeForm.name')}
                        maxLength={30}
                    />
                </div>
                <div className={`${contactMeFormStyle.inputContainer} row`}>
                    <ITextInput
                        size={'medium'}
                        onChangeHandler={formik.handleChange('senderEmail')}
                        variant={'outlined'}
                        error={formik.touched.senderEmail && Boolean(formik.errors.senderEmail)}
                        helperText={formik.touched.senderEmail && formik.errors.senderEmail}
                        value={formik.values.senderEmail}
                        inputLabel={t('contactMeForm.email')}
                        maxLength={50}
                    />
                </div>
                <div className={`${contactMeFormStyle.inputContainer} row`}>
                    <ITextInput
                        size={'medium'}
                        onChangeHandler={formik.handleChange('message')}
                        variant={'outlined'}
                        error={formik.touched.message && Boolean(formik.errors.message)}
                        helperText={formik.touched.message && formik.errors.message}
                        value={formik.values.message}
                        inputLabel={t('contactMeForm.message')}
                        multiline={true}
                        maxLength={300}
                    />
                </div>
                <div className={`${contactMeFormStyle.lettersCounter} row`}>
                    <span>{formik.values.message.length}/300</span>
                </div>
                <div className={`${contactMeFormStyle.recaptcha} row`}>
                    <span>
                        <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY} />
                    </span>
                </div>

                <div className={`${contactMeFormStyle.submitButtonContainer} row`}>
                    <IButton className={`${commonStyles.defaultButton}`} title={t('contactMeForm.submit')} type={'submit'} />
                </div>
            </form>
        </div>
    );
};

export default ContactMeForm;
