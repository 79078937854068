import { RotatingSquare } from 'react-loader-spinner';
import loaderStyle from './Loader.module.scss';

const Loader = () => {
    return (
        <div className={loaderStyle.loaderContainer}>
            <RotatingSquare
                visible={true}
                height='150'
                width='150'
                color='#0a0a0a'
                ariaLabel='rotating-square-loading'
                wrapperStyle={{}}
                wrapperClass=''
            />
        </div>
    );
};

export default Loader;
