import contactsStyle from './Contacts.module.scss';
import { observer } from 'mobx-react';
import ProtfolioStore from '../../stores/protfolio/Protfolio.store';
import { PROTFOLIO_STORE } from '../../stores/constants';
import rootStores from '../../stores/index';
import ContactMeForm from '../../components/contactMeForm/ContactMeForm';
import { useTranslation } from 'react-i18next';
import { ISendEmailPayload } from '../../interfaces/interfaces';

const protfolioStore = rootStores[PROTFOLIO_STORE] as ProtfolioStore;

const Contacts = observer(() => {
    const { sendEmail } = protfolioStore;
    const { t } = useTranslation();

    const sumbitFormHandler = (payload: ISendEmailPayload) => {
        sendEmail(payload);
    };
    return (
        <div className={`${contactsStyle.contactsContainer}`}>
            <div className={`${contactsStyle.titleContainer} row`}>
                <span>{t('contacts.title')}</span>
            </div>
            <ContactMeForm sumbitFormHandler={sumbitFormHandler} />
        </div>
    );
});

export default Contacts;
