import React from 'react';
import { useTranslation } from 'react-i18next';
import footerStyle from './Footer.module.scss';
import { BLANK } from '../../constants/constants';
import ClientConfig from '../../config';
import { Languages } from '../../emuns/enums';

const Footer = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { socialMedia } = ClientConfig;

    const socialClickHandler = (url: string) => {
        window.open(url, BLANK);
    };
    return (
        <footer className={`${footerStyle.footerContainer}`}>
            <div className={`${footerStyle.footerRow} row`}>
                <div className={`${footerStyle.footerColumn} col-12 col-md-5`}>
                    <div className={`${footerStyle.footerRowInformation} row`}>
                        <span>{t('footer.location')}</span>
                    </div>
                    <div className={`${footerStyle.footerRowInformation} row`}>
                        <span>{t('footer.myName')}</span>
                    </div>
                    <div className={`${footerStyle.footerRowInformation} row`}>
                        <span>{ClientConfig.phoneNumber}</span>
                    </div>
                    <div className={`${footerStyle.footerRowInformation} ${footerStyle.socialIconsContainer} row`}>
                        {socialMedia?.map((social, i) =>
                            React.cloneElement(social.icon, {
                                onClick: () => socialClickHandler(social.url),
                                className: footerStyle.socialIcon,
                                key: i,
                            })
                        )}
                    </div>
                </div>
                <div className={`${footerStyle.footerColumn} col-12 col-md-7`}>
                    <div className={`${footerStyle.footerQuoteRowInformation} ${language === Languages.HEBREW ? footerStyle.he : ''} row`}>
                        <span>{t('footer.quote')}</span>
                    </div>
                    <div className={`${footerStyle.footerQuoteRowInformation} ${language === Languages.HEBREW ? footerStyle.he : ''} row`}>
                        <span className={footerStyle.author}>{t('footer.author')}</span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
