import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IProjectsImage } from '../../../interfaces/interfaces';

interface IProps {
    images: IProjectsImage[];
}

const ImagesCarousle = (props: IProps) => {
    const { images } = props;

    return (
        <Carousel>
            {images?.map((image, i) => (
                <div key={i}>
                    <img src={image.image} alt='' />
                </div>
            ))}
        </Carousel>
    );
};

export default ImagesCarousle;
