import { Route, Routes } from 'react-router-dom';
import Home from '../screens/home/Home';
import { RoutesUrl } from './RouterUrls';
import NotFound from '../screens/notFound/NotFound';
import About from '../screens/about/About';
import Projects from '../screens/projects/Projects';
import Contacts from '../screens/contacts/Contacts';

const Router = () => {
    return (
        <Routes>
            <Route path={RoutesUrl.home} element={<Home />}></Route>
            <Route path={RoutesUrl.about} element={<About />}></Route>
            <Route path={RoutesUrl.projects} element={<Projects />}></Route>
            <Route path={RoutesUrl.contacts} element={<Contacts />}></Route>
            <Route path='*' element={<NotFound />}></Route>
        </Routes>
    );
};

export default Router;
