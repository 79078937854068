import { ICompanysJourney } from '../../../../interfaces/interfaces';
import companyStyle from './Company.module.scss';
import Divider from '@mui/material/Divider';

interface IProps {
    company: ICompanysJourney;
}

const Company = (props: IProps) => {
    const { company } = props;
    return (
        <div className={companyStyle.companyContainer}>
            <div className={companyStyle.title}>
                <span>{company.comanyName}</span>
            </div>
            <div className={companyStyle.role}>
                <span>{company.role}</span>
            </div>
            <Divider />
            <div className={companyStyle.years}>
                <span>{company.years}</span>
            </div>
        </div>
    );
};

export default Company;
