const contactMeForm = {
    requiredField: 'שדה חובה',
    invalidEmail: 'כתובת אימייל אינה תקינה',
    name: 'שם',
    email: 'אימייל',
    message: 'הודעה',
    submit: 'שלח',
};

export default contactMeForm;
