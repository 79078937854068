import { useTranslation } from 'react-i18next';
import contactStyle from './Contact.module.scss';
import { useNavigate } from 'react-router-dom';
import { RoutesUrl } from '../../../router/RouterUrls';

import { observer } from 'mobx-react';

import ProtfolioStore from '../../../stores/protfolio/Protfolio.store';
import { PROTFOLIO_STORE } from '../../../stores/constants';
import rootStores from '../../../stores/index';

const protfolioStore = rootStores[PROTFOLIO_STORE] as ProtfolioStore;

const Contact = observer(() => {
    const { setActiveRoute } = protfolioStore;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigationHandler = () => {
        setActiveRoute(RoutesUrl.contacts);
        navigate(RoutesUrl.contacts);
    };

    return (
        <section className={contactStyle.contactContainer}>
            <div className={`${contactStyle.textContainer} row`}>
                <span onClick={navigationHandler}>{t('contact.contact')}</span>
            </div>
        </section>
    );
});

export default Contact;
