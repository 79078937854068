import myJourneyStyle from './MyJourney.module.scss';
import { myJourney } from '../../../data/MyJourney';
import Company from './company/Company';
import { useTranslation } from 'react-i18next';
import AutoPlayCarousle from '../../autoPlayCarousle/AutoPlayCarousle';
import { Languages } from '../../../emuns/enums';

const MyJourney = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    return (
        <section className={myJourneyStyle.myJourneyContainer}>
            <AutoPlayCarousle />
            <div className={`${myJourneyStyle.myJourneyMainRowContainer} ${language === Languages.HEBREW ? myJourneyStyle.he : ''} row`}>
                <div className={`${myJourneyStyle.column} ${myJourneyStyle.titleColumn} col-12 col-md-6`}>
                    <span>{t('myJourney.myJourney')}</span>
                </div>
                <div className={`${myJourneyStyle.column} ${myJourneyStyle.companiesColumn} col-12 col-md-6`}>
                    <div className={myJourneyStyle.companiesContainer}>
                        {myJourney?.map((company, i) => (
                            <Company key={i} company={company} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MyJourney;
