import React from 'react';
import aboutStyle from './About.module.scss';
import commonStyles from '../../styles/commonStyles.module.scss';
import { observer } from 'mobx-react';
import IButton from '../../components/elements/iButton/IButton';
import { useTranslation } from 'react-i18next';

const About = observer(() => {
    const { t } = useTranslation();

    const downloadHandler = () => {
        const pdfPath = window.location.origin + '/assets/resume/CV_Tomer_Halali.pdf';
        const link = document.createElement('a');
        link.href = pdfPath;
        link.download = 'CV_Tomer_Halali.pdf';
        link.click();
    };
    return (
        <div className={`${aboutStyle.aboutContainer}`}>
            <div className={aboutStyle.informationContainer}>
                <h2>Summary</h2>
                <p>
                    I am a highly motivated software developer offering uncompromising professionalism, creativity, and a commitment to achieving and
                    exceeding defined goals. Adaptable and a quick learner with the ability to work effectively in a fast-paced, dynamic environment.
                    Eager to contribute technical acumen, creative problem-solving, and a passion for innovative development to a growing
                    organization.
                </p>

                <h2>Work Experience</h2>
                <h3 style={{ paddingBottom: '1em' }}>Front-End Developer – Pentera (2024-Present)</h3>

                <h3>Front-End Developer – Amdocs (2021-2024)</h3>
                <ul>
                    <li>Developed and maintained responsive web applications using Typescript HTML and CSS/SASS.</li>
                    <li>Implemented user interfaces and enhanced user experiences using frameworks like React.</li>
                    <li>Collaborated with designers and backend developers to ensure seamless integration of backend systems.</li>
                    <li>Conducted code reviews, identified bugs, and implemented necessary fixes to ensure high-quality code.</li>
                    <li>Actively participated in agile development processes, including sprint planning and daily stand-ups.</li>
                    <li>Customer On-Site Support: Successfully resolved production defects involving customer collaboration.</li>
                </ul>

                <h3>Full Stack Developer – WebTech-Inv (2021-2021)</h3>
                <ul>
                    <li>
                        End-to-end development of systems, including backend using Java, MySQL, Liferay Portal, and frontend using HTML, CSS, and
                        JavaScript (JSP).
                    </li>
                    <li>Worked in Agile methodology.</li>
                </ul>

                <h2>Self-Projects</h2>
                <p>
                    <strong>E-Commerce:</strong> Full-stack development of an E-Commerce web application using React TS, MobX, MongoDB, Express
                    (NodeJS), Vercel.
                </p>
                <p>
                    <strong>Tennis Score:</strong> Full-stack development of a web application empowering users to store and track their match scores
                    against other players using ReactJS, Redux, MySQL, Express (NodeJS), Vercel, PlanetScale.
                </p>
                <p>
                    <strong>Restaurant - Menu and Orders Management App:</strong> Shipping orders and self-pickups, allowing users to make orders from
                    the restaurant. Features include various restaurant menus, navigation, and contact using React Native, Express (NodeJS), MongoDB,
                    Twilio, Heroku.
                </p>

                <h2>Education and Qualifications</h2>
                <p>Bachelor of Science (BSc) in Computer Science, Sapir Academic College (2017-2020), GPA: 88.</p>
                <p>Graduated from Ort Yad Leibovitz High School, Netanya (2007-2010).</p>

                <h2>Volunteering</h2>
                <p>
                    <strong>2019-2020:</strong> "Ogen Kehilaty"- Beer-Sheva, Supporting and teaching teenagers at risk.
                </p>
                <p>
                    <strong>2018-2020:</strong> A personal mentor for a child who’s being treated at “Soroka” hospital, oncology department.
                </p>

                <h2>Military Service</h2>
                <p>
                    <strong>2010-2013:</strong> Tank technician in “Mahane Natan” in Beer-Sheva.
                </p>
                <div className={`${aboutStyle.buttonContainer} row`}>
                    <IButton onClick={downloadHandler} className={commonStyles.defaultButton} title={t('about.getMyResume')} />
                </div>
            </div>
        </div>
    );
});

export default About;
