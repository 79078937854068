import { ICompanysJourney } from '../interfaces/interfaces';

export const myJourney: ICompanysJourney[] = [
    {
        comanyName: 'Pentera',
        role: 'Front-End Developer ',
        years: '2024 - Today',
    },
    {
        comanyName: 'Amdocs',
        role: 'Front-End Developer ',
        years: '2021 - 2024',
    },
    {
        comanyName: 'Webtech Innovation',
        role: 'Full Stack Developer',
        years: '2021 - 2021',
    },
];
