const header = {
    home: 'ראשי',
    about: 'מי אני',
    projects: 'פרויקטים',
    contacts: 'יצירת קשר',
    english: 'English',
    hebrew: 'עברית',
};

export default header;
