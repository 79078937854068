import ProjectCard from '../../components/projectCard/ProjectCard';
import projectsStyle from './Projects.module.scss';
import commonStyle from '../../styles/commonStyles.module.scss';
import { observer } from 'mobx-react';
import { projects } from '../../data/projects';

const Projects = observer(() => {
    return (
        <div className={`${projectsStyle.projectsContainer}`}>
            <div>
                {projects?.map((project, i) => (
                    <div key={i} className={projectsStyle.projectCardContainer}>
                        <ProjectCard className={i % 2 === 0 ? commonStyle.projectCardSoftGray : commonStyle.projectCardBlack} project={project} />
                    </div>
                ))}
            </div>
        </div>
    );
});

export default Projects;
